import 'bootstrap/dist/css/bootstrap.min.css';
import 'rsuite/dist/rsuite.min.css';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppContext } from './store/AppContext';
import AppRouter from './routes/AppRouter';
import CustomSpinner1 from '../shared/ui/CustomSpinners/CustomSpinner1';
import Footer from '../shared/ui/Footer/Footer';
import ScrollToTop from '../shared/ui/ScrollToTop';
import { updateContext } from '../shared/helpers/logic/CommonLogic';
import Navbar from '../widgets/navBar/NavBar';

const App: React.FC = observer(() => {
	const { player, partner } = useContext(AppContext);
	const [loading, setLoading] = useState<boolean>(true);
    
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				await updateContext(player, partner);
			} catch (error) {
				console.error("Ошибка при обновлении контекста:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);
    
	return (
		<BrowserRouter>
			<div className='app_inner_container'>
				<Navbar loading={loading} />
				{loading ? (
					<CustomSpinner1 />
				) : (
					<>
						<div className='app_content_container'>
							<AppRouter />
						</div>
					</>
				)}
				<Footer />
				<ScrollToTop />
			</div>
		</BrowserRouter>
	)
});

export default App;