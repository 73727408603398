import React, { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import './PlayerNicknameSearch.css';
import { PlusCircle, Search } from 'react-bootstrap-icons';
import { observer } from 'mobx-react-lite';
import { IPlayerNicknameSearchModel } from '../model/IPlayerNicknameSearch';
import { handleSearchSubmit, handleSelectPlayer } from '../lib/helpers';

interface IPlayerNicknameSearchProps {
    onPlayerSelect: (player: IPlayerNicknameSearchModel) => void;
}

export const PlayerNicknameSearch: React.FC<IPlayerNicknameSearchProps> = observer(({ onPlayerSelect }) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState<IPlayerNicknameSearchModel[] | null>([]);
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const handleInputChange = (e: any) => {
        let value: string = e.target.value as string;
        if (value.length >= 3) { setSearchValue(value); }
    };
    
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            
            handleSearchSubmit({
                searchValue,
                setSearchResults,
                setShowAlert,
                setErrorMessage,
            });
        }
    };
    
    return (
        <div className='search_player_container'>
            {showAlert && (
                <Alert className='search_player_alert' variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    {errorMessage}
                </Alert>
            )}
            
            <div className='search_player_top'>
                <Form className="search_player_bar">
                    <Form.Control
                        name="search"
                        autoComplete="off"
                        placeholder="ВВЕДИТЕ НИКНЕЙМ"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        className="rounded-pill"
                    />
                </Form>
                
                <div>
                    <button type="submit" className='select_player_button' onClick={(e) => {
                        e.preventDefault();
                        handleSearchSubmit({
                            searchValue,
                            setSearchResults,
                            setShowAlert,
                            setErrorMessage,
                        });
                    }}>
                        
                        <Search />
                    </button>
                </div>
            </div>
            
            {searchResults !== null && searchResults.length > 0 && (
                <div className="players_selecor_block">
                    {searchResults.map((result) => (
                        <div className="searched_players_nickname" key={result.playerId} >
                            <div className='searched_players_nickname_logo'>
                                <img className='searched_players_nickname_logo' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${result.avatarUrl}`} alt="" />
                            </div>
                            
                            <div className='searched_players_nickname_playername'>
                                <a href={`/player/${result.playerId}`} target='__blank'>
                                    {result.playerName}
                                </a>
                            </div>
                            
                            <PlusCircle className='searched_players_nickname_plus' onClick={() => handleSelectPlayer(result, onPlayerSelect)} size={27} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
})