import { authInstance, guestInstance } from "..";
import { IMatchInfoModel } from "../../../entities/match/model/IMatchInfoModel";
import { ICommonTournamentModel } from "../../../entities/tournament/model/dto/ICommonTournamentModel";
import { IFullTournamentInfoModel } from "../../../entities/tournament/model/dto/IFullTournamentInfoModel";
import { ITournamentInfoModel } from "../../../entities/tournament/model/dto/ITournamentInfoModel";
import { TournamentSF } from "../../../entities/tournament/model/TournamentSF";
import { ICalculateFinalMatchDateModel } from "../../models/ICalculateFinalMatchDateModel";

export function serializeParams(params: any): string {
    const queryParts: string[] = [];
    for (const key in params) {
        if (params[key] !== undefined && params[key] !== null) {
            if (Array.isArray(params[key])) {
                params[key].forEach((val: string) => {
                    queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
                });
            } else {
                queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
            }
        }
    }
    return queryParts.join('&');
}

export const getTournamentById = async (
    tournamentId: string | undefined
): Promise<ITournamentInfoModel | null> => {
    try {
        const response = await guestInstance.get(`TournamentsCommon/GetTournamentInfoById?tournamentId=${tournamentId}`);
        return response.data as ITournamentInfoModel;
    } catch (e) {
        console.error("Error fetching tournament data:", e);
        return null;
    }
};

export const getFullTournamentInfoById = async (
    tournamentId: string | undefined
): Promise<IFullTournamentInfoModel | null> => {
    try {
        const response = await guestInstance.get(`TournamentsCommon/GetFullTournamentInfoById?tournamentId=${tournamentId}`);
        return response.data as IFullTournamentInfoModel;
    } catch (e) {
        console.error("Error fetching tournament full data:", e);
        return null;
    }
};

export const getTournamentsByFilters = async (
    filters: TournamentSF
): Promise<{ tournaments: ICommonTournamentModel[], totalCount: number }> => {
    try {
        const queryString = serializeParams(filters);
        const response = await guestInstance.get(`TournamentsCommon/GetTournamentsByFilters?${queryString}`);
        const totalCount = parseInt(response.headers['x-total-count'], 10);
        return { tournaments: response.data, totalCount };
    } catch (error) {
        console.error('Error fetching tournaments:', error);
        throw error;
    }
};

export const participateInTournament = (
    tournamentId: string | undefined,
    teamId: string | null | undefined
): Promise<{ success: boolean; errorMessage?: string }> => {
    return authInstance.get(`TournamentsCommon/RegisterTeam?tournamentId=${tournamentId}&teamId=${teamId}`)
        .then(response => {
            if (response.status === 200) {
                return { success: true };
            } else if (response.status === 400) {
                return { success: false, errorMessage: response.data };
            } else {
                return { success: false, errorMessage: "����������� ������" };
            }
        })
        .catch(e => {
            console.error("Error participating in tournament:", e.response?.data || e.message);
            return { success: false, errorMessage: e.response?.data || e.message };
        });
};

export const calculateThirdPlaceDate = async (
    calculateData: ICalculateFinalMatchDateModel
): Promise<string | false> => {
    try { 
        const response = await authInstance.post('TournamentsCommon/CalculateMinDateForFinalMatch',  calculateData);
        return response.data;
    } catch (e: any) {
        console.log(e.response?.data?.message || 'Error calculating date');
        return false;
    }
};

export const getMatchInfo = async (
    matchId: string
): Promise<IMatchInfoModel | false> => {
    try { 
        const response = await authInstance.get(`Matches/GetMatchInfo?matchId=${matchId}`);
        return response.data;
    } catch (e: any) {
        console.log(e.response?.data?.message || 'Error while getting match info');
        return false;
    }
};