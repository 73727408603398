import './MainPage.css';
import "react-multi-carousel/lib/styles.css";

import { useContext, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Carousel from 'react-multi-carousel';
import ServersMap from './Components/ServersMap/ServersMap';
import { useModal } from "../../shared/helpers/hooks/useModal";
import RegModalWindow from "../../shared/ui/forms/RegModalWindow/RegModalWindow";

import { TournamentConstants } from "../../shared/helpers/constants/tournamentConstants";

import { ReactComponent as ChartPie } from './assets/buttons/chartPie.svg';
import { ReactComponent as UsersFour } from './assets/buttons/usersFour.svg';
import { ReactComponent as CupFirst } from './assets/buttons/cupFirst.svg';
import { ReactComponent as PartnerIcon1 } from './assets/partners/partnerIcon1.svg';
import { ReactComponent as PartnerIcon2 } from './assets/partners/partnerIcon2.svg';
import { ReactComponent as PartnerIcon3 } from './assets/partners/partnerIcon3.svg';
import { ReactComponent as PartnerIcon4 } from './assets/partners/partnerIcon4.svg'
import { ReactComponent as ArrowLeft } from './assets/gameCarousel/arrowLeft.svg';
import { ReactComponent as ArrowRight } from './assets/gameCarousel/arrowRight.svg';
import { ReactComponent as ArrowBottom } from './assets/buttons/arrowBottom.svg';
import { ReactComponent as AboutAsButton } from './assets/buttons/aboutAsButton.svg';

import aboutNeva1 from './assets/aboutNeva/aboutNeva1.png';
import aboutNeva2 from './assets/aboutNeva/aboutNeva2.png';
import aboutNeva3 from './assets/aboutNeva/aboutNeva3.png';
import headerImg from './assets/header/headerImg.png'
import questionsImg from './assets/questionsImg.png';
import { AppContext } from '../../app/store/AppContext';

interface ICurrentDesc {
    id: number;
    title: string;
    description: string;
    imgUrl: React.JSX.Element;
    bgImgUrl: string;
}

interface IQuestion {
    id: number;
    title: string;
    description: string;
}

const currentDesc: ICurrentDesc[] = [
    {
        id: 1,
        title: 'Соревнования для всех уровней мастерства',
        description: `
            Участвуй в соревнованиях, которые подходят именно тебе — от новичков до профессионалов.
            Найди свой уровень, улучшай навыки и продвигайся вверх по киберспортивной лестнице.
        `,
        imgUrl: <CupFirst />,
        bgImgUrl: aboutNeva1
    },
    {
        id: 2,
        title: 'Интегрированное лобби',
        description: `
            Регистрация и участие в турнирах стали проще благодаря удобному интегрированному лобби. Быстрый вход через
            Steam и автоматическое распределение команд — всё для твоего комфорта.
        `,
        imgUrl: <UsersFour />,
        bgImgUrl: aboutNeva2
    },
    {
        id: 3,
        title: 'Детализированная статистика',
        description: `
            Следи за своими достижениями и анализируй каждую игру с помощью глубокой статистики. Узнай свои сильные и
            слабые стороны, чтобы стать лучше с каждым матчем.
        `,
        imgUrl: <ChartPie />,
        bgImgUrl: aboutNeva3
    },
];

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        centerMode: true,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 5,
        centerMode: true,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const questions: IQuestion[] = [
    {
        id: 1,
        title: 'Могу ли я участвовать в турнирах, если я новичок?',
        description: 'Да, наша платформа предоставляет турниры для игроков всех уровней. Вы можете начать с любительских соревнований, чтобы получить опыт и развивать свои навыки, а затем перейти на более высокий уровень. NEVA — это место, где каждый может найти подходящее соревнование.'
    },
    {
        id: 2,
        title: 'Как проходят турниры?',
        description: 'Всё просто! После регистрации на турнире вы автоматически попадаете в лобби, где распределяются команды. Турниры проходят онлайн, с фиксированными правилами и расписанием. По завершении матча результаты обновляются автоматически, а статистика становится доступна в вашем профиле.'
    },
    {
        id: 3,
        title: 'Сколько это стоит?',
        description: 'На данный момент, пока платформа находится в стадии развития, вы можете пользоваться всеми доступными функциями абсолютно бесплатно. Присоединяйтесь сейчас и станьте частью растущего киберспортивного сообщества!'
    },
    {
        id: 4,
        title: 'Планируется ли добавление новых функций?',
        description: 'Да, мы постоянно развиваем платформу! В ближайших планах — расширение списка поддерживаемых игр, добавление инструментов для стриминга, интеграция дополнительных социальных функций и улучшение статистики. Следите за новостями, чтобы быть в курсе всех обновлений!'
    },
    {
        id: 5,
        title: 'Что делать, если я столкнулся с проблемой на платформе?',
        description: 'Если вы столкнулись с любой технической или организационной проблемой, свяжитесь с нашей службой поддержки. Мы всегда готовы помочь! Вы можете обратиться через форму обратной связи на сайте или написать нам на указанный email. Мы оперативно решаем все вопросы.'
    }
]

const MainPage: React.FC = () => {
    const { player } = useContext(AppContext)
    const navigate = useNavigate();
    const [currentImg, setCurrentImg] = useState<number>(1);
    const carouselRef = useRef<any>(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [isDirectionRight, setDirection] = useState<boolean>(false);
    const [activeQuestionId, setActiveQuestionId] = useState<number | null>(null);
    const totalSlides = TournamentConstants.Games.length;
    const { isVisible: showRegModal, openModal: openRegModal, closeModal: closeRegModal } = useModal();

    const handleToggleQuestion = (id: number) => {
        setActiveQuestionId((prev) => (prev === id ? null : id));
    };

    const handleNext = () => {
        setDirection(true);
        carouselRef.current?.next();
    };

    const handlePrev = () => {
        setDirection(false);
        carouselRef.current?.previous();
    };

    const handleAfterChange = (index: number) => {
        const normalizedIndex = index % totalSlides;
        setActiveSlide(normalizedIndex < 0 ? normalizedIndex + totalSlides : normalizedIndex);
    };

    const currentDescData = currentDesc.find((item) => item.id === currentImg);

    return (
        <>
            <div className="main_container">
                <header className="main_header">
                    <div className='main_header_nevaText'>
                        <span>N</span>ORTHERN<br />
                        <span>E</span>SPORTS<br />
                        <span>V</span>ERSUS<br />
                        <span>A</span>RENA
                    </div>

                    <div className='main_header_imgContaienr'>
                        <img className='main_header_img' src={headerImg} alt='NEVA' />
                    </div>

                    <div className="main_header_desc">
                        <p>
                            Погрузись в мир киберспорта — участвуй
                            <br />
                            в турнирах, отслеживай достижения и стань
                            <br />
                            частью команды на платформе будущего
                        </p>
                        {!player?.playerId ? (
                            <button className='main_header_descButton' onClick={openRegModal}>ЗАРЕГИСТРИРОВАТЬСЯ</button>
                        ) : (
                            <button className='main_header_descButton' onClick={() => navigate('/games')}>ВЫБРАТЬ ТУРНИР</button>
                        )}
                    </div>
                </header>

                <div className='neva_aboutAs'>
                    <h3 className='neva_aboutAs_title'>Здесь растут чемпионы<br />и создаются рекорды</h3>

                    <div className='neva_aboutAs_subTitles'>
                        <a className='neva_aboutAs_link' href="https://greenspacedev.ru/" target="_blank"
                            rel="noopener noreferrer">
                            <h5 className='neva_aboutAs_subTitle'>
                                Подробнее о нас
                                <AboutAsButton className='neva_aboutAs_subTitleIcon' />
                            </h5>
                        </a>

                        <p className='neva_aboutAs_subTitleDesc'>
                            NEVA — молодая, но амбициозная платформа, созданная для игроков, фанатов и организаторов.
                            Участвуй в первых турнирах, отслеживай свои достижения и расти вместе с нами. Твоя команда,
                            твои победы, твой вклад в будущее киберспорта — всё начинается здесь.
                        </p>
                    </div>

                    <div className='neva_aboutAs_counters'>
                        <div className='neva_aboutAs_counter'>
                            <div className='neva_aboutAs_counterTitle'>2024</div>
                            <p className='neva_aboutAs_counterSubTitle'>год основания</p>
                        </div>

                        <div className='neva_aboutAs_counter'>
                            <div className='neva_aboutAs_counterTitle'>7000+</div>
                            <p className='neva_aboutAs_counterSubTitle'>уникальных игроков</p>
                        </div>

                        <div className='neva_aboutAs_counter'>
                            <div className='neva_aboutAs_counterTitle'>10000+</div>
                            <p className='neva_aboutAs_counterSubTitle'>матчей проведено</p>
                        </div>

                        <div className='neva_aboutAs_counter'>
                            <div className='neva_aboutAs_counterTitle'>48</div>
                            <p className='neva_aboutAs_counterSubTitle'>регионов</p>
                        </div>
                    </div>
                </div>

                <div className="neva_games">
                    <div className="neva_games_title">
                        Прими вызов — соревнуйся в<br />топовых дисциплинах
                    </div>

                    <Carousel
                        ref={carouselRef}
                        responsive={responsive}
                        infinite={true}
                        draggable={false}
                        swipeable={false}
                        itemClass='neva_games_cardItem'
                        arrows={false}
                        beforeChange={(previousSlide, state) => handleAfterChange(state.currentSlide)}
                    >
                        {TournamentConstants.Games.map((g, idx) => {
                            let isActive: boolean = isDirectionRight
                                ? ((activeSlide + 3) % totalSlides) === idx
                                : ((activeSlide + 1) % totalSlides) === idx;

                            let gameLink: string = g.gameTypeId ? `/game/${g.gameTypeId}` : '/soon';

                            return (
                                <img
                                    className={`neva_games_cardItem_img ${isActive ? 'active' : ''}`}
                                    onClick={() => navigate(gameLink)}
                                    src={g.gameBaner}
                                    alt={g.gameName}
                                    key={idx}
                                />
                            );
                        })}
                    </Carousel>

                    <div className="neva_games_custom_buttons">
                        <button onClick={handlePrev} className="neva_games_custom_button">
                            <ArrowRight className='neva_games_custom_arrowIcon' />
                        </button>
                        <button onClick={handleNext} className="neva_games_custom_button">
                            <ArrowLeft className='neva_games_custom_arrowIcon' />
                        </button>
                    </div>
                </div>

                <div className="about_nevaWrapper">
                    <p className="about_neva_upperTitle">Твои победы заслуживают большего!</p>

                    <div className="about_neva">
                        <div className="about_neva_content">
                            <div className="about_neva_content_block">
                                <h3 className="about_neva_title">
                                    Мы сделали всё простым
                                    <br />
                                    и доступным
                                </h3>
                                <p className="about_neva_desc">
                                    На платформе NEVA каждая твоя победа имеет значение. Мы создали
                                    <br />
                                    пространство, где соревнования доступны для всех, а участие стало
                                    <br />
                                    максимально простым и удобным. Здесь ты можешь сосредоточиться
                                    <br />
                                    на игре, не переживая о сложностях регистрации или технических
                                    <br />
                                    проблемах. Мы заботимся о твоём опыте, чтобы ты мог покорять
                                    <br />
                                    вершины киберспорта.
                                </p>
                            </div>

                            <div className="about_neva_content_block2">
                                <p className="about_neva_buttonsTitle">{currentDescData?.title}</p>

                                <div className="about_neva_buttons">
                                    <button
                                        className={`about_neva_button ${currentImg === 1 ? 'active' : ''}`}
                                        onClick={() => setCurrentImg(1)}
                                    >
                                        <ChartPie className='about_neva_buttonIcon' />
                                    </button>
                                    <button
                                        className={`about_neva_button ${currentImg === 2 ? 'active' : ''}`}
                                        onClick={() => setCurrentImg(2)}
                                    >
                                        <UsersFour className='about_neva_buttonIcon' />
                                    </button>
                                    <button
                                        className={`about_neva_button ${currentImg === 3 ? 'active' : ''}`}
                                        onClick={() => setCurrentImg(3)}
                                    >
                                        <CupFirst className='about_neva_buttonIcon' />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="about_neva_imgContainer">
                            <div
                                className="about_neva_img"
                                style={{ backgroundImage: `url(${currentDescData?.bgImgUrl})` }}
                            >
                                <div className="about_neva_img_desc">{currentDescData?.description}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main_serversMap_wrapper">
                    <ServersMap />
                </div>

                <div className='neva_staple'>
                    <div className='neva_staple_content_blockCercle'></div>

                    <div className='neva_staple_Titles'>
                        <h3 className='neva_staple_Title'>
                            Твоя победа ближе,
                            <br />
                            чем ты думаешь
                        </h3>

                        <p className='neva_staple_SubTitle'>
                            Каждый чемпион начинал с первого шага.
                            <br />
                            Сделай его вместе с NEVA и покажи, чего ты достоин
                        </p>
                    </div>

                    <div className='neva_staple_content'>
                        <div className='neva_staple_content_block'>
                            <div className='neva_staple_content_blockCircl'>1</div>

                            <h3 className='neva_staple_content_blockTitle'>Зарегистрируйся<br />и стань частью команды
                            </h3>

                            <p className='neva_staple_content_blockSubTitle'>
                                Создай свой аккаунт и подключи<br />профиль Steam, чтобы сразу начать<br />своё
                                киберспортивное путешествие
                            </p>
                        </div>

                        <div className='neva_staple_content_block'>
                            <div className='neva_staple_content_blockCircl'>2</div>

                            <h3 className='neva_staple_content_blockTitle'>Прими вызов и вступи в бой!</h3>

                            <p className='neva_staple_content_blockSubTitle'>
                                Выбирай турнир по своему уровню,<br />находи соперников и покажи,<br />на что ты способен
                            </p>
                        </div>

                        <div className='neva_staple_content_block'>
                            <div className='neva_staple_content_blockCircl'>3</div>

                            <h3 className='neva_staple_content_blockTitle'>Забери свою победу!</h3>

                            <p className='neva_staple_content_blockSubTitle'>
                                Сражайся, выигрывай и стань лидером!<br />Каждая победа — шаг к вершине!
                            </p>
                        </div>
                    </div>
                </div>

                <div className="main_partners">
                    <div className='main_partners_descs'>
                        <div className='main_partners_desc'>
                            <h3 className='main_partners_descTitle'>С нами идут те,<br />кто верит в будущее</h3>
                            <p className='main_partners_descSubTitle'>
                                Вместе мы работаем над тем, чтобы сделать<br />ваш игровой опыт ещё лучше.
                            </p>
                        </div>

                        <div className='main_partners_desc two'>
                            <p className='main_partners_descText'>
                                Крупные бренды уже доверились нам и поддерживают<br />развитие киберспортивного
                                сообщества.
                                Наши партнёры<br />разделяют наши ценности и помогают создавать платформу,<br />которой
                                можно
                                доверять.
                            </p>
                        </div>
                    </div>

                    <div className="partners_carousel">
                        <div className='partners_carouselGroup'>
                            <div className='partners_partner'>
                                <PartnerIcon1 className="partners_partnerIcon" />
                            </div>
                            <div className='partners_partner'>
                                <PartnerIcon2 className="partners_partnerIcon" />
                            </div>
                            <div className='partners_partner'>
                                <PartnerIcon3 className="partners_partnerIcon" />
                            </div>
                            <div className='partners_partner'>
                                <PartnerIcon4 className="partners_partnerIcon" />
                            </div>
                        </div>

                        <div className='partners_carouselGroup'>
                            <div className='partners_partner'>
                                <PartnerIcon1 className="partners_partnerIcon" />
                            </div>
                            <div className='partners_partner'>
                                <PartnerIcon2 className="partners_partnerIcon" />
                            </div>
                            <div className='partners_partner'>
                                <PartnerIcon3 className="partners_partnerIcon" />
                            </div>
                            <div className='partners_partner'>
                                <PartnerIcon4 className="partners_partnerIcon" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='main_questions'>
                    <div className='main_questionsTitles'>
                        <h3 className='main_questionsTitle'>Остались вопросы?</h3>
                        <p className='main_questionsSubTitle'>FAQs</p>
                    </div>

                    <div className='main_questions_content'>
                        <div className='main_questions_content_questions'>
                            {questions.map((q) => {
                                const isActiveCard = activeQuestionId === q.id;

                                return (
                                    <div
                                        className={`main_questions_content_questionsCard ${isActiveCard ? 'active' : ''}`}
                                        onClick={() => handleToggleQuestion(q.id)}
                                        key={q.id}
                                    >
                                        <div
                                            className='main_questions_content_questionsCardHeadline'
                                        >
                                            {q.title}
                                        </div>

                                        <ArrowBottom
                                            className={`main_questions_content_questionsArrowBottom ${isActiveCard ? 'active' : ''}`}
                                        />

                                        <div
                                            className={`main_questions_content_questionsCardDesc ${isActiveCard ? 'active' : ''}`}
                                        >
                                            <p>{q.description}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className='main_questions_content_imgContainer'>
                            <img className='main_questions_content_img' src={questionsImg} alt='NEVA' />
                        </div>
                    </div>
                </div>
                {!player?.playerId && (
                    <div className='main_join'>
                        <h3 className='main_joinTitle'>
                            Готов стать частью
                            <br />
                            <span className='main_joinTitleColor'>новой эры киберспорта?</span>
                        </h3>

                        <p className='main_joinSubTitle'>
                            Присоединяйся к NEVA и открой новые возможности для игры, побед и роста.
                            <br />
                            Твоя команда, твой турнир, твоя платформа — всё начинается здесь!
                        </p>

                        <button className='main_joinButton' onClick={openRegModal}>ЗАРЕГИСТРИРОВАТЬСЯ</button>
                    </div>
                )}
            </div>

            <RegModalWindow showModal={showRegModal} closeModal={closeRegModal} />
        </>
    );
};

export default MainPage;
