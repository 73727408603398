import { Dispatch, SetStateAction } from "react";
import { ICreateTeamViewModel } from "../../../entities/team/model/ICreateTeamViewModel";

export const useTeamHandlers = (
    setTeamData: Dispatch<SetStateAction<ICreateTeamViewModel>>,
    setCurrentAvatar: Dispatch<SetStateAction<string>>
) => {
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | Date | null,
        name?: string
    ) => {
        if (event instanceof Date || event === null) {
            const value = event ? event.toISOString() : '';
            if (!name) return;
            
            setTeamData(prevTeamData => ({
                ...prevTeamData,
                [name]: value
            }));
        } else {
            const { name, value, type, checked } = event.target as HTMLInputElement;
            if (type === 'checkbox') {
                setTeamData(prevTeamData => ({
                    ...prevTeamData,
                    [name]: checked
                }));
            } else {
                setTeamData(prevTeamData => ({
                    ...prevTeamData,
                    [name]: value
                }));
            }
        }
    };
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            setTeamData(prevTeam => ({ ...prevTeam, logoUrl: file }));
            const imageUrl = URL.createObjectURL(e.target.files[0]);
            setCurrentAvatar(imageUrl);
        }
    }; 
    
    const handleDateChange = (date: Date | null) => {
        if (date && !isNaN(date.getTime())) {
            const value = date.toISOString();
            setTeamData(prev => ({
                ...prev,
                creationDate: value,
            }));
        } else {
            setTeamData(prev => ({
                ...prev,
                creationDate: '',
            }));
        }
    };
    
    return { handleInputChange, handleFileChange, handleDateChange };
};