import { TournamentConstants } from "../constants/tournamentConstants";
import { checkContext } from "../../api/userAPI";
import { IStoreModel } from "../../models/dto/IStoreModel";


interface ILoginFunction<T> {
    loginPlayer?: (data: T) => void;
    loginPartner?: (data: T) => void;
}

export const updateContext = async <T, U>(
    context?: ILoginFunction<T>,
    partnerContext?: ILoginFunction<U>
) => {
    try {
        const [userData] = await Promise.all([checkContext()]);
        
        if (userData) {
            let userDataFormal: IStoreModel = userData;
            if (userDataFormal.player && userDataFormal.player !== null && context && context.loginPlayer) {
                context.loginPlayer(userDataFormal.player as T);
            } else if (userDataFormal.partner && userDataFormal.partner !== null && partnerContext && partnerContext.loginPartner) {
                partnerContext?.loginPartner(userDataFormal.partner as U);
            }
        }
    } catch (e: any) {
        console.error("An error occurred while updating context:", e.message);
    }
};

export const getGameIconSrc = (gameTypeId: string): string => {
    switch (gameTypeId) {
        case TournamentConstants.CS2.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}cs2_small_icon.jpg`;
        case TournamentConstants.DOTA2.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}dota2_small_icon.png`;
        case TournamentConstants.APEX.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}apex_small_icon.jpg`;
        case TournamentConstants.FORTNITE.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}fortnite_small_icon.jpg`;
        case TournamentConstants.HEARTHSTONE.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}hearthstone_small_icon.jpg`;
        case TournamentConstants.PUBG.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}pubg_small_icon.jpg`;
        case TournamentConstants.ROCKETLIAGUE.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}rocketliague_small_icon.jpg`;
        case TournamentConstants.VALORANT.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}valorant_small_icon.jpg`;
        case TournamentConstants.THEFAINALS.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}thefinals_small_icon.jpg`;
        case TournamentConstants.WORLDOFTANKS.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}wot_small_icon.jpg`;
        case TournamentConstants.RAINBOWSIXSIEGE.gameTypeId:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}rainbowsixsiege_small_icon.jpg`;
        default:
            return `${process.env.REACT_APP_IMG_STORAGE_URL}empty_small_icon.jpg`;
    }
}

export const getGameTypeName = (gameTypeId: string): string => {
    switch (gameTypeId) {
        case TournamentConstants.CS2.gameTypeId:
            return TournamentConstants.CS2.gameName;
        case TournamentConstants.DOTA2.gameTypeId:
            return TournamentConstants.DOTA2.gameName;
        case TournamentConstants.APEX.gameTypeId:
            return TournamentConstants.APEX.gameName;
        case TournamentConstants.FORTNITE.gameTypeId:
            return TournamentConstants.FORTNITE.gameName;
        case TournamentConstants.HEARTHSTONE.gameTypeId:
            return TournamentConstants.HEARTHSTONE.gameName;
        case TournamentConstants.PUBG.gameTypeId:
            return TournamentConstants.PUBG.gameName;
        case TournamentConstants.ROCKETLIAGUE.gameTypeId:
            return TournamentConstants.ROCKETLIAGUE.gameName;
        case TournamentConstants.VALORANT.gameTypeId:
            return TournamentConstants.VALORANT.gameName;
        case TournamentConstants.THEFAINALS.gameTypeId:
            return TournamentConstants.THEFAINALS.gameName;
        case TournamentConstants.WORLDOFTANKS.gameTypeId:
            return TournamentConstants.WORLDOFTANKS.gameName;
        case TournamentConstants.RAINBOWSIXSIEGE.gameTypeId:
            return TournamentConstants.RAINBOWSIXSIEGE.gameName;
        default:
            return "NoGameName";
    }
}