import React from 'react';
import './CommonAlert.css';
import { Modal, Button } from 'react-bootstrap'; 

type ButtonProps = {
    text: string;
    onClick: () => void;
};

type AlertProps = {
    buttons: ButtonProps[];
    show: boolean;
    onHide?: () => void;
    alertText: string;
};

const CommonAlert: React.FC<AlertProps> = ({ buttons, alertText, show, onHide}) => {
    return (
        <Modal onHide={onHide} className='myAlert_container' show={show}>
            <Modal.Body className='myAlert_modal_body'>
                <h3 className='myAlert_text'>{alertText}</h3>
                <div className="myAlert">
                    {
                        buttons.map((button, index) => (
                            <Button className='myAlert_button' onClick={button.onClick} key={index} variant="outline-success">
                                {button.text}
                            </Button>
                        ))
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CommonAlert; 