
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App'; 
import { AppContextProvider } from './app/store/AppContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>
);
