import { authInstance, guestInstance } from ".";
import { IMatchLobbyModel } from "../../entities/lobby/model/IMatchLobbyModel";
import { ITeamPageModel } from "../../entities/team/model/ITeamPageModel";
import { ITeamStoreModel } from "../../entities/team/model/ITeamStoreModel";
import { IPlayerNicknameSearchModel } from "../../widgets/playerNicknameSearch/model/IPlayerNicknameSearch";
import { PlayerSF } from "../../widgets/playerNicknameSearch/model/PlayerSF";
import { serializeParams } from "./tournament/tournamentActionsAPI";

export const createTeam = async (
    teamData: FormData
): Promise<ITeamStoreModel | false> => {
    try { 
        const response = await authInstance.post('PlayerActions/CreateTeam', teamData);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка создания Команды');
        return false;
    }
};

export const updateTeam = async (
    teamData: FormData,
    teamId: string | undefined
): Promise<ITeamStoreModel | false> => {
    try { 
        const response = await authInstance.put(`PlayerActions/UpdateTeam/${teamId}`, teamData);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка обновления данных Команды');
        return false;
    }
};

export const getTeamInfo = async (
    teamId:string | undefined
): Promise<ITeamPageModel | false> => {
    try {
        const response = await guestInstance.get(`PlayerActions/GetTeamInfo?teamId=${teamId}`);
        return response.data;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка получения данных о Команде');
        return false;
    }
};

// export const addPlayerToTeam = async (
//     selectedPlayerModel: IPlayerSelectorPlayerModel,
//     teamId: string
// ): Promise<boolean> => {
//     let model = {
//         playerId: selectedPlayerModel.playerId,
//         teamId: teamId
//     } as IPlayerToTeamViewModel;
//     try {
//         const response = await authInstance.post(`PlayerActions/AddPlayerToTeam`, model);
//         return response.status === 200;
//     } catch (e: any) {
//         alert(e.response?.data?.message || 'An error occurred');
//         return false;
//     }
// };

// export const removePlayerToTeam = async (
//     selectedPlayerModel: IPlayerSelectorPlayerModel,
//     teamId: string
// ): Promise<boolean> => {
//     let model = {
//         playerId: selectedPlayerModel.playerId,
//         teamId: teamId
//     } as IPlayerToTeamViewModel;
    
//     try {
//         const response = await authInstance.post(`PlayerActions/RemovePlayerFromTeam`, model);
//         return response.status === 200;
//     } catch (e: any) {
//         alert(e.response?.data?.message || 'An error occurred');
//         return false;
//     }
// };

export const createLobby = async (
    playerId: string
): Promise<string> => {
    if (playerId === '') { return '' }
    try {
        const response = await authInstance.get(`PlayerActions/CreateTeamLobby?playerId=${playerId}`);
        return response.data as string;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка создания Лобби');
        return '';
    }
};

export const getPlayersByNicknamePart = async (
    nicknamePart: string
): Promise<IPlayerNicknameSearchModel[] | null> => {
    if (nicknamePart === '') { return null }
    try {
        const response = await authInstance.get(`PlayerActions/GetPlayersByNicknamePart?nicknamePart=${nicknamePart}`);
        return response.data as IPlayerNicknameSearchModel[];
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка поиска по Игрокам');
        return null;
    }
}

export const getPlayersByFilters = async (
    filters: PlayerSF
): Promise<IPlayerNicknameSearchModel[] | null> => {
    try {
        const queryString = serializeParams(filters);
        const response = await authInstance.get(`PlayerActions/GetPlayersByFilters?${queryString}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching players:', error);
        throw error;
    }
};

export const addPlayerToLobby = async (
    playerId: string,
    lobbyId: string
): Promise<boolean> => {
    try {
        const response = await authInstance.get(`PlayerActions/AddPlayerToLobby?playerId=${playerId}&lobbyId=${lobbyId}`);
        return response.status === 200;
    } catch (e: any) {
        alert(e.response?.data?.message || 'Ошибка добавления в Лобби');
        return false;
    }
}

export const approveTournamentByOrganisator = async (
    tournamentId: string | undefined
): Promise<boolean> => {
    return authInstance.get(`TournamentsCommon/ApproveTournamentByOrganisator?&tournamentId=${tournamentId}`)
        .then(response => {
            return response.status === 200;
        })
        .catch(e => {
            console.error("Ошибка одобрения турнира организатором:", e.response?.data || e.message);
            return false;
        });
};

export const getActivePlayerLobbies = async (
    playerId: string
): Promise<IMatchLobbyModel[] | false> => {
    try {
        const response = await guestInstance.get(`PlayerActions/GetActivePlayerLobbies?playerId=${playerId}`);
        return response.data;
    } catch (e: any) {
        console.log(e.response?.data?.message || 'Ошибка получения списка лобби');
        return false;
    }
};
