import React from 'react';
import { IMatchLobbyModel } from '../../../../entities/lobby/model/IMatchLobbyModel';
import { useNavigate } from 'react-router-dom';
import { BoxArrowInRight } from 'react-bootstrap-icons';
import { TournamentConstants } from '../../../../shared/helpers/constants/tournamentConstants';

const LobbyUnit: React.FC<{
    lobbyModel: IMatchLobbyModel;
}> = ({ lobbyModel }) => {
    const navigate  = useNavigate();
    
    return (
        <div className='lobby_unit' >
            {lobbyModel.gameTypeId === TournamentConstants.CS2.gameTypeId && (
                <img src={process.env.REACT_APP_IMG_STORAGE_URL + "cs2_small_icon.jpg"} className="tournament_cell_img" alt="img" />
            )}
            {lobbyModel.gameTypeId === TournamentConstants.DOTA2.gameTypeId && (
                <img src={process.env.REACT_APP_IMG_STORAGE_URL + "dota2_small_icon.png"} className="tournament_cell_img" alt="img" />
            )}
            <span>{lobbyModel.name}</span> <button onClick={() => navigate(`/matchlobby/${lobbyModel.gameTypeId}/${lobbyModel.lobbyId}`)}><BoxArrowInRight size={20} /></button>
        </div>
    ); 
};

export default LobbyUnit;