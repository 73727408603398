export class DefaultAvatars {

    static readonly tournamentAvatars = [
        'tournament_avatar1.jpg',
        'tournament_avatar2.jpg',
        'tournament_avatar3.jpg'
    ];

    static readonly userAvatars = [
        'UserAvatar1.png',
        'UserAvatar2.png',
        'UserAvatar3.png',
        'UserAvatar4.png',
        'UserAvatar5.png',
        'UserAvatar6.png',
        'UserAvatar7.png',
        'UserAvatar8.png',
        'UserAvatar10.png',
        'UserAvatar11.png',
        'UserAvatar12.png',
        'UserAvatar13.png',
        'UserAvatar14.png',
        'UserAvatar15.png'
    ];
    static readonly partnerAvatars = [
        'PartnerAvatar1.png',
        'PartnerAvatar2.png',
        'PartnerAvatar3.png'
    ];

    static readonly teamAvatars = [
        'team.png',
        'team2.png',
        'team3.png'
    ];

    static getTournamentAvatar(index: number) {
        return `${DefaultAvatars.tournamentAvatars[index % DefaultAvatars.tournamentAvatars.length]}`;
    }

    static getUserAvatar(index: number) {
        return `${DefaultAvatars.userAvatars[index % DefaultAvatars.userAvatars.length]}`;
    }
    static getPartnerAvatar(index: number) {
        return `${DefaultAvatars.partnerAvatars[index % DefaultAvatars.partnerAvatars.length]}`;
    }

    static getTeamAvatar(index: number) {
        return `${DefaultAvatars.teamAvatars[index % DefaultAvatars.teamAvatars.length]}`;
    }
}
