import { useState, useRef, useEffect, SetStateAction } from 'react';

export const useAvatar = (
    avatars: string[],
    randomAvatarIdx: number,
    setRandomAvatarIdx: React.Dispatch<SetStateAction<number>>
) => {
    const [currentAvatar, setCurrentAvatar] = useState<string>(
        `${process.env.REACT_APP_IMG_STORAGE_URL}${avatars[randomAvatarIdx] || avatars[0]}`
    );
    const fileInputRef = useRef<HTMLInputElement>(null);

    const rotateRight = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setRandomAvatarIdx((prev) => (prev + 1) % avatars.length);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    useEffect(() => {
        setCurrentAvatar(`${process.env.REACT_APP_IMG_STORAGE_URL}${avatars[randomAvatarIdx] || avatars[0]}`);
    }, [randomAvatarIdx, avatars]);

    return { currentAvatar, rotateRight, fileInputRef, setCurrentAvatar, randomAvatarIdx };
};