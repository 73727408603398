import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../app/store/AppContext";
import { SystemRolesEnum } from "../../../shared/models/enums/SystemRolesEnum";

import { IPlayerNicknameSearchModel } from "../../../widgets/playerNicknameSearch/model/IPlayerNicknameSearch";
import { ITeamParticipantVM } from "../lib/ITeamParticipantVM";
import { ITeamPageModel } from "../../../entities/team/model/ITeamPageModel";

import { TeamParticipantEnum } from "../lib/TeamParticipantEnum";
import { ITeamCreatorVM } from "../lib/ITeamCreatorVM";

export const useTeamMembers = (teamInfo: ITeamPageModel | undefined, creator: ITeamCreatorVM, isEditMode: boolean, userSystemRole: SystemRolesEnum.Player | SystemRolesEnum.Partner) => {
    const { player, partner } = useContext(AppContext);
    
    const [teamPlayers, setTeamPlayers] = useState<IPlayerNicknameSearchModel[]>([]);
    const [teamStaff, setTeamStaff] = useState<IPlayerNicknameSearchModel[]>([]);
    const [visibleRolesMemberId, setVisibleRolesMemberId] = useState<string | null>(null);
    
    const [teamParticipants, setTeamParticipants] = useState<ITeamParticipantVM[]>(
        creator && creator.creatorId && !isEditMode
        ? [
            {
                participantId: creator.creatorId,
                participantTypes:
                    userSystemRole === SystemRolesEnum.Player
                        ? [TeamParticipantEnum.Creator, TeamParticipantEnum.Player, TeamParticipantEnum.Captain]
                        : [TeamParticipantEnum.Owner, TeamParticipantEnum.Creator],
            },
        ]
        : []
    );
    
    // ----- TEAM PLAYERS -----
    useEffect(() => {
        if (isEditMode && teamInfo) {
            setTeamPlayers(
                teamInfo.players?.map(p => ({
                    playerId: p.playerId,
                    playerName: p.login,
                    avatarUrl: p.avatarURL
                })) ?? []
            );
        } else {
            setTeamPlayers(prevTeamPlayers => [
                ...prevTeamPlayers, {
                    playerId: player?.playerId ?? null,
                    playerName: player?.login ?? null,
                    avatarUrl: player?.avatarURL ?? null
                }
            ]);
        }
    }, [isEditMode, teamInfo]);
    
    const handleAddTeamPlayer = (member: IPlayerNicknameSearchModel) => {
        const memberExists = teamPlayers.some(
            p => p.playerId === member.playerId
        );
        
        const isMaxPlayers = teamPlayers.length >= 5;
        
        const isMemperCoach = teamParticipants.some(tp => tp.participantId === member.playerId && tp.participantTypes.includes(TeamParticipantEnum.Coach));
        
        if (memberExists) {
            return;
        }
        
        if (isMaxPlayers) {
            alert("В команде максимальное число игроков!");
            return;
        }
        
        if (isMemperCoach) {
            alert("Тренер не может быть игроком!");
            return;
        }
        
        setTeamPlayers(prevPlayers => [
            ...prevPlayers, member
        ]);
        
        handleAddOrRemoveParticipants(member.playerId ?? '', TeamParticipantEnum.Player, true);
    };
    
    const handleRemoveTeamPlayer = (member: IPlayerNicknameSearchModel) => {
        if (!isEditMode && member.playerId === creator.creatorId) {
            return;
        }
        
        let newTeamPlayers = teamPlayers.filter(p => p.playerId !== member.playerId);
        
        setTeamPlayers(newTeamPlayers);
        
        handleAddOrRemoveParticipants(member.playerId ?? '', TeamParticipantEnum.Player, false);
    };
    
    // ----- TEAM STAFF -----
    useEffect(() => {
        if (isEditMode && teamInfo) {
            const combinedStaff = [
                ...teamInfo.coaches,
                ...teamInfo.managers,
                ...teamInfo.contentMakers,
                teamInfo.captain,
            ];
            
            const filtredStaff = combinedStaff.filter(
                (player, index, arr) =>
                    index === arr.findIndex(p => p.playerId === player.playerId)
            );
            
            const staff = filtredStaff.map(p => ({
                playerId: p.playerId,
                playerName: p.login,
                avatarUrl: p.avatarURL
            }));
            
            setTeamStaff(staff);
        } else {
            setTeamStaff(prevTeamPlayers => [
                ...prevTeamPlayers, {
                    playerId: player?.playerId ?? null,
                    playerName: player?.login ?? null,
                    avatarUrl: player?.avatarURL ?? null
                }
            ]);
        }
    }, [isEditMode, teamInfo]);
    
    const handleAddTeamStaff = (member: IPlayerNicknameSearchModel) => {
        const memberExists = teamStaff.some(
            p => p.playerId === member.playerId
        );
        
        if (memberExists) {
            return;
        }
        
        setTeamStaff(prevStaff => [
            ...prevStaff, member
        ]);
    };
    
    const handleRemoveTeamStaff = (memberId: string) => {
        if (!isEditMode && memberId === creator.creatorId) {
            return;
        }
        
        let newTeamPlayers = teamStaff.filter(p => p.playerId !== memberId);
        setTeamStaff(newTeamPlayers);
        
        handleAddOrRemoveParticipants(memberId, TeamParticipantEnum.Captain, false);
        handleAddOrRemoveParticipants(memberId, TeamParticipantEnum.ContentMaker, false);
        handleAddOrRemoveParticipants(memberId, TeamParticipantEnum.Manager, false);
        handleAddOrRemoveParticipants(memberId, TeamParticipantEnum.Coach, false);
    };
    
    // ----- TEAM Participants -----
    const handleAddOrRemoveParticipants = (memberId: string, memberRole: TeamParticipantEnum, isAdd: boolean) => {
        const memberExists = teamParticipants.some(
            participant => participant.participantId === memberId
        );
        
        if (memberId) {
            if (isAdd) {
                if (memberExists) {
                    setTeamParticipants(prevParticipants =>
                        prevParticipants.map(participant => {
                            if (participant.participantId === memberId) {
                                if (participant.participantTypes.includes(memberRole)) {
                                    return participant;
                                }
                                
                                return {
                                    ...participant,
                                    participantTypes: [...participant.participantTypes, memberRole],
                                };
                            }
                            
                            return participant;
                        })
                    );
                } else {
                    setTeamParticipants(prevParticipants => [
                        ...prevParticipants,
                        { participantId: memberId, participantTypes: [memberRole] },
                    ]);
                }
            } else {
                setTeamParticipants(prevParticipants =>
                    prevParticipants.reduce<ITeamParticipantVM[]>((acc, participant) => {
                        if (participant.participantId === memberId) {
                            const updatedRoles = participant.participantTypes.filter(
                                role => role !== memberRole
                            );
                            
                            if (updatedRoles.length > 0) {
                                acc.push({
                                    ...participant,
                                    participantTypes: updatedRoles,
                                });
                            }
                        } else {
                            acc.push(participant);
                        }
                        
                        return acc;
                    }, [])
                );
            }
        }
    };
    
    useEffect(() => {
        if (teamInfo && isEditMode) {
            let updatedParticipants: ITeamParticipantVM[] = [];
            
            teamInfo.players.forEach(p => {
                updatedParticipants.push({
                    participantId: p.playerId ?? '',
                    participantTypes: [TeamParticipantEnum.Player]
                });
            });
            
            teamInfo.managers.forEach(p => {
                updatedParticipants.push({
                    participantId: p.playerId ?? '',
                    participantTypes: [TeamParticipantEnum.Manager]
                });
            });
            
            teamInfo.coaches.forEach(p => {
                updatedParticipants.push({
                    participantId: p.playerId ?? '',
                    participantTypes: [TeamParticipantEnum.Coach]
                });
            });
            
            teamInfo.contentMakers.forEach(p => {
                updatedParticipants.push({
                    participantId: p.playerId ?? '',
                    participantTypes: [TeamParticipantEnum.ContentMaker]
                });
            });
            
            if (teamInfo.captain?.playerId) {
                updatedParticipants.push({
                    participantId: teamInfo.captain.playerId,
                    participantTypes: [TeamParticipantEnum.Captain]
                });
            }
            
            if (teamInfo.owner?.userId) {
                updatedParticipants.push({
                    participantId: teamInfo.owner.userId,
                    participantTypes: [TeamParticipantEnum.Captain]
                });
            }
            
            if (teamInfo.playerCreator?.playerId) {
                updatedParticipants.push({
                    participantId: teamInfo.playerCreator.playerId,
                    participantTypes: [TeamParticipantEnum.Creator]
                });
            }
            
            setTeamParticipants(prevParticipants => {
                const mergedParticipants: ITeamParticipantVM[] = [...prevParticipants];
                
                updatedParticipants.forEach(newParticipant => {
                    const existingParticipant = mergedParticipants.find(p => p.participantId === newParticipant.participantId)
                    
                    if (existingParticipant) {
                        existingParticipant.participantTypes = Array.from(new Set([...existingParticipant.participantTypes, ...newParticipant.participantTypes]));
                    } else {
                        mergedParticipants.push(newParticipant);
                    }
                });
                
                return mergedParticipants;
            });
        } else {
            if (player && player?.playerId && userSystemRole === SystemRolesEnum.Player) {
                if (player.playerId) {
                    setTeamParticipants(prevParticipants => [
                        ...prevParticipants, {  
                            participantId: player.playerId ?? '',
                            participantTypes: [
                                TeamParticipantEnum.Creator,
                                TeamParticipantEnum.Captain,
                                TeamParticipantEnum.Player
                            ]   
                        }
                    ]);
                }
            } else if (partner && partner?.partnerId && userSystemRole === SystemRolesEnum.Partner) {
                setTeamParticipants(prevParticipants => [
                    ...prevParticipants, {
                        participantId: partner.partnerId ?? '',
                        participantTypes: [
                            TeamParticipantEnum.Owner,
                            TeamParticipantEnum.Creator,
                        ]
                    }  
                ]);
            }
        }
    }, [teamInfo, isEditMode]);
    
    // ----- Cheks -----
    const isMemberWithRole = (memberId: string, role: TeamParticipantEnum): boolean => {
        if (!memberId) return false;
        
        const member = teamParticipants.find(p => p.participantId === memberId);
        
        return member ? member.participantTypes.includes(role) : false;
    };
    
    const isCaptainExists = (memberId: string) => {
        const isCaptain = teamParticipants.some(p => p.participantTypes.includes(TeamParticipantEnum.Captain) && p.participantId !== memberId);
        return !isCaptain;
    };
    
    const isVisibleCoachCheckbox = (memberId: string) => {
        if (memberId) {
            return !teamParticipants.some(p => p.participantId === memberId && p.participantTypes.includes(TeamParticipantEnum.Player));
        }
    }
    
    const handleVisibleMemberRoles = (memberId: string) => {
        setVisibleRolesMemberId(prev => (prev === memberId ? null : memberId));
    };
    
    const isVisibleRemoveButton = (memberId: string) => {
        if (!isEditMode && memberId === creator.creatorId) {
            return false;
        }
        
        return true;
    }
    
    return {
        teamPlayers,
        teamStaff,
        teamParticipants,
        
        visibleRolesMemberId,
        
        handleAddTeamPlayer,
        handleRemoveTeamPlayer,
        
        handleAddTeamStaff,
        handleRemoveTeamStaff,
        
        handleAddOrRemoveParticipants,
        
        isMemberWithRole,
        isCaptainExists,
        isVisibleCoachCheckbox,
        isVisibleRemoveButton,
        
        handleVisibleMemberRoles,
    };

};