import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Routes, Route, useParams, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AppContext } from '../store/AppContext';
import CustomSpinner1 from '../../shared/ui/CustomSpinners/CustomSpinner1';
import { adminRoutes, authRoutes, publicRoutes } from './routes';
import { CreateUpdateTeamPage } from '../../pages/createUpdateTeam';
import { ErrorBoundary } from 'react-error-boundary';
import { NotFound } from '../../pages/notFound';


const AppRouter: React.FC = observer(() => {
  const { player } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const preloadPages = async () => {
      await import('../../pages/gamesList');
      await import('../../pages/tournament');
      await import('../../pages/player');
    };
    preloadPages();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1000);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const isExceptionPath = location.pathname.includes('/confirm-email') || location.pathname.includes('/reset-password');

    if (isMobile && !isExceptionPath && location.pathname !== '/warning') {
      navigate('/warning');
    }
  }, [location.pathname, navigate]);

  const RouteWrapper: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
    const { teamId } = useParams();
    return <>{children}</>;
  });

  return (
    <ErrorBoundary FallbackComponent={NotFound}>
      <Suspense fallback={<CustomSpinner1 />}>
        <Routes>
          {publicRoutes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}

          {player?.isAuth && authRoutes.map(({ path, Component }) => {
            if (path === '/create-team') {
              return <Route key={path} path={path} element={<Component />} />;
            }

            return <Route key={path} path={path} element={<Component />} />;
          })}

          {player?.isAuth && (
            <Route
              path="/update-team/:teamId"
              element={
                <RouteWrapper>
                  <CreateUpdateTeamPage />
                </RouteWrapper>
              }
            />
          )}

          {player?.isAdmin &&
            adminRoutes.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
});

export default AppRouter;
