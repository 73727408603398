import { useState, useEffect, useContext } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AppContext } from '../../../app/store/AppContext';
import { INotificationModel } from '../model/INotificationModel';

export const useNotifications = (isVisible: boolean, onUnreadNotificationsChange: (hasUnread: boolean) => void) => {
    const [notifications, setNotifications] = useState<INotificationModel[]>([]);
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const { player } = useContext(AppContext);

    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_BACKEND_API_URL}notificationHub/${player?.playerId}`)
            .withAutomaticReconnect([500, 1000, 2000])
            .build();

        setConnection(connect);

        connect.start()
            .then(() => {
                connect.on('ReceiveNotifications', (receivedNotifications: INotificationModel[]) => {
                    setNotifications(receivedNotifications);
                    const hasUnread = receivedNotifications.some(n => n.state === 1);
                    onUnreadNotificationsChange(hasUnread);
                });
                connect.on('ReceiveNotification', (notification: INotificationModel) => {
                    setNotifications(prevNotifications => {
                        const exists = prevNotifications.some(n => n.notificationId === notification.notificationId);
                        if (!exists) {
                            return [...prevNotifications, notification];
                        }
                        return prevNotifications;
                    });
                    const hasUnread = notification.state === 1;
                    onUnreadNotificationsChange(hasUnread);
                });
                connect.on("ReceiveNotificationStateUpdate", (notificationState: INotificationModel) => {
                    setNotifications(prevNotifications => {
                        const index = prevNotifications.findIndex(n => n.notificationId === notificationState.notificationId);
                        if (index !== -1) {
                            return prevNotifications.map(n =>
                                n.notificationId === notificationState.notificationId ? notificationState : n
                            );
                        } else {
                            return [...prevNotifications, notificationState];
                        }
                    });
                });
            })
            .catch(err => console.error('SignalR Connection Error: ', err));

        return () => {
            if (connection) {
                connection.stop();
            }
        };
    }, [onUnreadNotificationsChange, player?.playerId]);

    useEffect(() => {
        if (isVisible && connection) {
            const unreadNotifications = notifications.filter(n => n.state === 1 && !n.isPong);

            if (unreadNotifications.length > 0) {
                const timer = setTimeout(() => {
                    unreadNotifications.forEach(notification => {
                        if (!notification.isPong) {
                            connection.invoke("MarkNotificationsAsRead", notification.notificationId, player?.playerId);
                        }
                    });
                }, 3000); // 3 seconds delay

                return () => clearTimeout(timer);
            }
        }
    }, [isVisible, connection, notifications, player?.playerId]);

    return { notifications, connection };
};
