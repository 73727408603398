import axios, { InternalAxiosRequestConfig} from 'axios';
import { handleApiError } from './errorHandler';

const guestInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL + `api/`,
    withCredentials: true,
}); 

const authInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL + `api/`,
    withCredentials: true,
});

const authInterceptor = async (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token');

    if (token) {
        config.headers = config.headers || {};
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
};

const errorInterceptor = (error: any) => {
    const errorMessage = handleApiError(error);

    return Promise.reject(new Error(errorMessage));
};

authInstance.interceptors.request.use(authInterceptor);

authInstance.interceptors.response.use(
    response => response, 
    errorInterceptor
);

guestInstance.interceptors.response.use(
    response => response,
    // errorInterceptor
);

export { guestInstance, authInstance };

