import { lazy } from 'react';
import MainPage from '../../pages/MainPage/MainPage';
import { NotFound } from '../../pages/notFound';
import WarningPage from '../../pages/screenWarning/WarningPage';

const PlayerPage = lazy(() => import('../../pages/player').then((module) => ({ default: module.PlayerPage })));
const RegistrationPage = lazy(() => import('../../pages/registration').then((module) => ({ default: module.RegistrationPage })));
const ResetPasswordPage = lazy(() => import('../../pages/resetPassword').then((module) => ({ default: module.ResetPasswordPage })));
const TournamentPage = lazy(() => import('../../pages/tournament').then((module) => ({ default: module.TournamentPage })));
const MatchPage = lazy(() => import('../../pages/match').then((module) => ({ default: module.MatchPage })));
const GamesListPage = lazy(() => import('../../pages/gamesList').then((module) => ({ default: module.GamesListPage })));
const LobbyPage = lazy(() => import('../../pages/lobby').then((module) => ({ default: module.LobbyPage })));
const GamePage = lazy(() => import('../../pages/game').then((module) => ({ default: module.GamePage })));
const TeamPage = lazy(() => import('../../pages/team').then((module) => ({ default: module.TeamPage })));
const PartnerPage = lazy(() => import('../../pages/partner').then((module) => ({ default: module.PartnerPage })));
const UserTeamsPage = lazy(() => import('../../pages/userTeams').then((module) => ({ default: module.UserTeamsPage })));
const CreateUpdateTeamPage = lazy(() => import('../../pages/createUpdateTeam').then((module) => ({ default: module.CreateUpdateTeamPage })));
const CreateUpdateTournamentPage = lazy(() => import('../../pages/сreateUpdateTournament').then((module) => ({ default: module.CreateUpdateTournamentPage })));
const AdminPanel = lazy(() => import('../../pages/AdminPanel/AdminPanel'));
const ComingSoonFiller = lazy(() => import('../../shared/ui/ComingSoonFiller/ComingSoonFiller'));

type RouteConfig = {
    path: string;
    Component: React.ComponentType<any>;
};

export const publicRoutes: RouteConfig[] = [
    { path: '/', Component: MainPage },
    { path: '*', Component: NotFound },
    { path: '/player/:playerId', Component: PlayerPage },
    { path: '/team/:teamId', Component: TeamPage },
    { path: '/game/:gameTypeId', Component: GamePage },
    { path: '/confirm-email/:userId/:code', Component: RegistrationPage },
    { path: '/reset-password/:userId/:code', Component: ResetPasswordPage },
    { path: '/tournament/:tournamentId', Component: TournamentPage },
    { path: '/match/:matchId', Component: MatchPage },
    { path: '/games', Component: GamesListPage },
    { path: '/matchlobby/:gameTypeId/:matchLobbyId', Component: LobbyPage },
    { path: '/soon', Component: ComingSoonFiller },
    { path: '/partner/:partnerId', Component: PartnerPage },
    { path: '/warning', Component: WarningPage }
];

export const authRoutes: RouteConfig[] = [
    { path: '/create-team', Component: CreateUpdateTeamPage },
    { path: '/user-teams', Component: UserTeamsPage },
];

export const adminRoutes: RouteConfig[] = [
    { path: '/create-tournament', Component: CreateUpdateTournamentPage },
    { path: '/update-tournament/:tournamentId', Component: CreateUpdateTournamentPage },
    { path: '/admin-panel/*', Component: AdminPanel }
];