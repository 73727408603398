interface IErrorResponse {
    message?: string;
    code?: number;
    errors?: string[];
}

export const handleApiError = (error: any): string => {
    let errorMessage = 'Произошла непредвиденная ошибка';

    if (error.response) {
        const responseData = error.response.data as IErrorResponse;

        if (Array.isArray(responseData.errors)) {
            errorMessage = responseData.errors.join(', ');
        }

        else if (responseData.message) {
            errorMessage = responseData.message;
        }
        else {
            errorMessage = 'Произошла ошибка на сервере';
        }
    }
    else if (error.request) {
        errorMessage = 'Ошибка связи с сервером';
    }
    else if ('message' in error) {
        errorMessage = error.message ?? 'Непредвиденная ошибка';
    }

    return errorMessage;
};