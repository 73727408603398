import './CreateUpdateTeamPage.css';
import '../../сreateUpdateTournament/ui/CreateUpdateTournamentPage.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container, Row, Tab, Col, Nav, Form, FloatingLabel, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import { ArrowClockwise, CaretDownFill, CaretUpFill, InfoCircleFill, X } from 'react-bootstrap-icons';
import { CustomProvider, DatePicker, DateRangePicker } from 'rsuite';
import { ruRU } from 'rsuite/esm/locales';

import { AppContext } from '../../../app/store/AppContext';
import { SystemRolesEnum } from '../../../shared/models/enums/SystemRolesEnum';
import { TeamParticipantEnum } from '../lib/TeamParticipantEnum';

import { TournamentConstants } from '../../../shared/helpers/constants/tournamentConstants';
import { DefaultAvatars } from '../../../shared/helpers/constants/defaultAvatars';

import { useAvatar } from '../../../shared/helpers/hooks/useAvatar';
import { useTeamHandlers } from '../hooks/useTeamHandlers';
import { useTeam } from '../hooks/useTeam';
import { useTeamMembers } from '../hooks/useTeamMembers';

import GooeyButton from '../../../shared/ui/buttons/GooeyButton/GooeyButton';
import { PlayerNicknameSearch } from '../../../widgets/playerNicknameSearch';
import { ITeamPageModel } from '../../../entities/team/model/ITeamPageModel';
import { ITeamCreatorVM } from '../lib/ITeamCreatorVM';

export const CreateUpdateTeamPage: React.FC = () => {
    const { teamId } = useParams();
    const { player, partner } = useContext(AppContext);
    const isEditMode = Boolean(teamId);
    const userSystemRole = player ? SystemRolesEnum.Player : SystemRolesEnum.Partner;    
    const [initTeamInfo, setInitTeamInfo] = useState<ITeamPageModel>();
    const [creator, setCreator] = useState<ITeamCreatorVM>({
        creatorId: null,
        creatorUserName: null,
        creatorAvatarURL: null,
    });
    
    const formRef1 = useRef<HTMLFormElement>(null);
    const formRef2 = useRef<HTMLFormElement>(null);
    
    const [randomAvatarIdx, setRandomAvatarIdx] = useState(0);
    
    const { afterToday } = DateRangePicker;
    
    useEffect(() => {
        let teamCreator: ITeamCreatorVM = creator;
        
        if (initTeamInfo && isEditMode) {
            if (initTeamInfo.playerCreator) {
                teamCreator = {
                    creatorId: initTeamInfo.playerCreator.playerId,
                    creatorUserName: initTeamInfo.playerCreator.login,
                    creatorAvatarURL: initTeamInfo.playerCreator.avatarURL ?? null,
                }
            } else if (initTeamInfo.partnerCreator) {
                teamCreator = {
                    creatorId: initTeamInfo.partnerCreator.partnerId,
                    creatorUserName: initTeamInfo.partnerCreator.login,
                    creatorAvatarURL: initTeamInfo.partnerCreator.logoUrl ?? null,
                }
            }
        } else {
            if (player) {
                teamCreator = {
                    creatorId: player.playerId,
                    creatorUserName: player.login,
                    creatorAvatarURL: player.avatarURL ?? null,
                }
            } else if (partner) {
                teamCreator = {
                    creatorId: partner.partnerId,
                    creatorUserName: partner.login,
                    creatorAvatarURL: partner.logoUrl ?? null,
                }
            }
        }
        
        setCreator(teamCreator);
    }, [initTeamInfo, isEditMode]);
    
    const {
        teamPlayers,
        teamStaff,
        teamParticipants,
        visibleRolesMemberId,
        handleAddTeamPlayer,
        handleRemoveTeamPlayer,
        handleAddTeamStaff,
        handleRemoveTeamStaff,
        handleAddOrRemoveParticipants,
        isMemberWithRole,
        isCaptainExists,
        isVisibleCoachCheckbox,
        isVisibleRemoveButton,
        handleVisibleMemberRoles
    } = useTeamMembers(initTeamInfo, creator, isEditMode, userSystemRole);
    
    const { 
        currentAvatar,
        fileInputRef,
        rotateRight,
        setCurrentAvatar
    } = useAvatar(DefaultAvatars.teamAvatars, randomAvatarIdx, setRandomAvatarIdx);
    
    const {
        teamData,
        setTeamData,
        handleTeamAction,
        isLoading,
        validated,
        validatedSecond,
        showAlert,
        teamInfo
    } = useTeam(isEditMode, teamStaff, teamParticipants, creator, userSystemRole, formRef1, formRef2, randomAvatarIdx, setCurrentAvatar);
    
    const {
        handleInputChange,
        handleFileChange,
        handleDateChange
    } = useTeamHandlers(setTeamData, setCurrentAvatar);
    
    useEffect(() => {
        setInitTeamInfo(teamInfo);
    }, [teamInfo]);
    
    const popoverPersonal = (
        <Popover >
            <Popover.Body className='infoDescRole'>
                <b>Капитан</b> - Участвует в выборе карт <br />
                <b>Тренер команды</b> - Спектатор матча <br />
                <b>Менеджер</b> - Управлялет командой <br />
                <b>Контент мэйкер</b> - Редактирует новости команды
                <br /><br />
                <strong>Капитан может быть только один!</strong>
                <strong>Тренер не может быть игроком</strong>
            </Popover.Body>
        </Popover>
    );
    
    return (
        <Container>
            <div className="create_tournament_container">
                <div className="create_tournament_header">
                    {isEditMode ?
                        'РЕДАКТИРОВАНИЕ КОМАНДЫ'
                        : 'СОЗДАНИЕ КОМАНДЫ'}
                    <GooeyButton onClick={handleTeamAction} isLoading={isLoading} disabled={isLoading}>
                        {isEditMode ?
                            'РЕДАКТИРОВАТЬ'
                            : 'СОЗДАТЬ КОМАНДУ'}
                    </GooeyButton>
                </div>
                
                <div className="create_tournament_main_container">
                    <Tab.Container defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav className="create-tournament-navs" variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" className="create-tournament-navlink">ОПИСАНИЕ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">СОЦИАЛЬНЫЕ СЕТИ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">ИГРОКИ КОМАНДЫ</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">ПЕРСОНАЛ КОМАНДЫ</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form ref={formRef1} className="create_tournament_form" noValidate validated={validated}>
                                            {showAlert && (
                                                <Alert variant='danger'>
                                                    Заполните все обязательные поля
                                                </Alert>
                                            )}
                                            
                                            <FloatingLabel label="НАЗВАНИЕ КОМАНДЫ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="name"
                                                    placeholder=""
                                                    value={teamData.name}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={32}
                                                    minLength={3}
                                                />
                                            </FloatingLabel>
                                            
                                            <FloatingLabel label="ТЭГ КОМАНДЫ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="tag"
                                                    placeholder=""
                                                    value={teamData.tag}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={8}
                                                    minLength={3}
                                                />
                                            </FloatingLabel>
                                            
                                            <FloatingLabel label="ОПИСАНИЕ КОМАНДЫ" className='create_tourn_label'>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={6}
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="description"
                                                    placeholder=""
                                                    value={teamData.description}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={250}
                                                />
                                            </FloatingLabel>
                                            
                                            <CustomProvider theme="dark" locale={ruRU}>
                                                <DatePicker
                                                    className="create_tournament_form_control"
                                                    format="dd/MM/yyyy"
                                                    placeholder="ДАТА СОЗДАНИЯ КОМАНДЫ"
                                                    name="creationDate"
                                                    value={teamData.creationDate ? new Date(teamData.creationDate) : null}
                                                    onChange={(value) => {
                                                        if (isEditMode) {
                                                            setTeamData(prevTeamData => ({
                                                                ...prevTeamData,
                                                                creationDate: value ? value.toISOString() : ''
                                                            }));
                                                        } else {
                                                            handleDateChange(value);
                                                        }
                                                    }}
                                                    shouldDisableDate={afterToday()}
                                                    disabled={isEditMode}
                                                />
                                            </CustomProvider>
                                            
                                            <FloatingLabel label="ГОРОД" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    required
                                                    name="city"
                                                    placeholder=""
                                                    value={teamData.city}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    maxLength={32}
                                                />
                                            </FloatingLabel>
                                            
                                            <Form.Select
                                                className="create_tournament_form_control option"
                                                name="gameTypeId"
                                                value={teamData.gameTypeId || ''}
                                                required
                                                onChange={handleInputChange}
                                            >
                                                <option
                                                    disabled={true}
                                                    value="">ИГРОВАЯ ДИСЦИПЛИНА</option>
                                                <option
                                                    disabled={!!player?.getTeamByGameTypeId(TournamentConstants.CS2.gameTypeId)}
                                                    value={TournamentConstants.CS2.gameTypeId}>CS2</option>
                                                <option
                                                    disabled={!!player?.getTeamByGameTypeId(TournamentConstants.DOTA2.gameTypeId)}
                                                    value={TournamentConstants.DOTA2.gameTypeId} >DOTA2</option>
                                                <option value="3" disabled >Fortnite</option>
                                            </Form.Select>
                                            
                                            <Form.Label>
                                                ЛОГОТИП КОМАНДЫ
                                            </Form.Label>
                                            
                                            <div className='createTournament_download_avatar_container'>
                                                <h3 className='createTournament_download_avatar_title'>Загрузите или выберите аватар</h3>
                                                <div className='createTournament_download_avatar'>
                                                    <Form.Group controlId="formFile" className="create_tournament_form_control date">
                                                        <Form.Control
                                                            ref={fileInputRef}
                                                            type="file"
                                                            name="logoUrl"
                                                            onChange={handleFileChange}
                                                        />
                                                    </Form.Group>
                                                    
                                                    <div className='createTournament_avatar_container'>
                                                        <div className='createTournament_avatar'>
                                                            <img src={currentAvatar} alt="User Avatar" />
                                                            <button className='createTournament_avatar_button' onClick={rotateRight}>
                                                                <ArrowClockwise className='avatar_button_arrowIcon' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Tab.Pane>
                                    
                                    <Tab.Pane eventKey="second">
                                        <Form ref={formRef2} className="create_tournament_form" noValidate validated={validatedSecond}>
                                            {showAlert && (
                                                <Alert variant='danger'>
                                                    Заполните все обязательные поля
                                                </Alert>
                                            )}
                                            
                                            <FloatingLabel label="ВЕБСАЙТ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    name="website"
                                                    placeholder=""
                                                    value={teamData.website || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                            
                                            <FloatingLabel label="ПОЧТА ДЛЯ СВЯЗИ" className='create_tourn_label'>
                                                <Form.Control
                                                    className="create_tournament_form_control"
                                                    name="contactEmail"
                                                    placeholder=""
                                                    value={teamData.contactEmail || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                />
                                            </FloatingLabel>
                                        </Form>
                                    </Tab.Pane>
                                    
                                    <Tab.Pane eventKey="third">
                                        <Form className="create_tournament_form" noValidate validated={validated}>
                                            {showAlert && (
                                                <Alert variant='danger'>
                                                    Заполните все обязательные поля
                                                </Alert>
                                            )}
                                            
                                            <div className='teammates_block'>
                                                <div className='teammates_block_header'>ИГРОКИ КОМАНДЫ</div>
                                                {teamPlayers.length !== 0 ? (
                                                    <div className='teammates__teamcreate_container'>
                                                        {teamPlayers.map((tp) => (
                                                            <div key={tp.playerId} className='teammates__teamcreate_unit'>
                                                                {isVisibleRemoveButton(tp.playerId ?? '') && (
                                                                    <X className='team_member_unit_name_x' onClick={() => handleRemoveTeamPlayer(tp)} />
                                                                )}
                                                                <img className='teammates__teamcreate_avatar' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${tp.avatarUrl}`} alt="" />
                                                                <a className='teammates__teamcreate_name' target='_blank' href={`/player/${tp.playerId}`}>{tp.playerName}</a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className='infoText'>В команде еще нет игроков</div>
                                                )}
                                                
                                                <PlayerNicknameSearch onPlayerSelect={handleAddTeamPlayer} />
                                            </div>
                                        </Form>
                                    </Tab.Pane>
                                    
                                    <Tab.Pane eventKey="fourth">
                                        <Form.Check className="create_tournament_form">
                                            {showAlert && (
                                                <Alert variant='danger'>
                                                    Заполните все обязательные поля
                                                </Alert>
                                            )}
                                            
                                            <div className='teammates_block'>
                                                <div className='teammates_block_header'>
                                                    ПЕРСОНАЛ КОМАНДЫ
                                                    <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverPersonal}>
                                                        <InfoCircleFill className='create_tournament_info' />
                                                    </OverlayTrigger>
                                                </div>
                                                
                                                {teamStaff.length !== 0 ? (
                                                    <div className='teammates__teamcreate_container'>
                                                        {teamStaff.map((member) => (
                                                            <div key={member.playerId} className='teammates__teamcreate_unit'>
                                                                {isVisibleRemoveButton(member.playerId ?? '') && (
                                                                    <X className='team_member_unit_name_x' onClick={() => handleRemoveTeamStaff(member.playerId ?? '')} />
                                                                )}
                                                                <img className='teammates__teamcreate_avatar' src={`${process.env.REACT_APP_IMG_STORAGE_URL}${member.avatarUrl}`} alt="" />
                                                                <a className='teammates__teamcreate_name' target='_blank' href={`/player/${member.playerId}`}>{member.playerName}</a>
                                                                <div className='rolesList_container'>
                                                                    <button onClick={() => {
                                                                        if (member.playerId) {
                                                                            handleVisibleMemberRoles(member.playerId)
                                                                        }
                                                                    }}>
                                                                        Роли
                                                                        {visibleRolesMemberId === member.playerId ? (
                                                                            <CaretUpFill className={`rolesList_arrrow ${visibleRolesMemberId}`} size={15} />
                                                                        ) : (
                                                                            <CaretDownFill className={`rolesList_arrrow ${visibleRolesMemberId}`} size={15} />
                                                                        )}
                                                                    </button>
                                                                    
                                                                    {visibleRolesMemberId === member.playerId && (
                                                                        <Form.Group className="create_tournament_form_checkboxes" controlId="memberRole">
                                                                            {isCaptainExists(member.playerId ?? '') && (
                                                                                <Form.Check
                                                                                    disabled={isMemberWithRole(member.playerId ?? '', TeamParticipantEnum.Captain) && !isEditMode}
                                                                                    label="Капитан"
                                                                                    type="checkbox"
                                                                                    checked={isMemberWithRole(member.playerId ?? '', TeamParticipantEnum.Captain)}
                                                                                    className='create_tournament_form_checkbox'
                                                                                    onChange={(e) => handleAddOrRemoveParticipants(member.playerId ?? '', TeamParticipantEnum.Captain, e.target.checked)}
                                                                                />
                                                                            )}
                                                                            
                                                                            {isVisibleCoachCheckbox(member.playerId ?? '') && (
                                                                                <Form.Check
                                                                                    disabled
                                                                                    label="Тренер"
                                                                                    type="checkbox"
                                                                                    checked={isMemberWithRole(member.playerId ?? '', TeamParticipantEnum.Coach)}
                                                                                    className='create_tournament_form_checkbox'
                                                                                    onChange={(e) => handleAddOrRemoveParticipants(member.playerId ?? '', TeamParticipantEnum.Coach, e.target.checked)}
                                                                                />
                                                                            )}
                                                                            
                                                                            <Form.Check
                                                                                disabled
                                                                                label="Менеджер"
                                                                                type="checkbox"
                                                                                checked={isMemberWithRole(member.playerId ?? '', TeamParticipantEnum.Manager)}
                                                                                className='create_tournament_form_checkbox'
                                                                                onChange={(e) => handleAddOrRemoveParticipants(member.playerId ?? '', TeamParticipantEnum.Manager, e.target.checked)}
                                                                            />
                                                                            
                                                                            <Form.Check
                                                                                disabled
                                                                                label="Контент мэйкер"
                                                                                type="checkbox"
                                                                                checked={isMemberWithRole(member.playerId ?? '', TeamParticipantEnum.ContentMaker)}
                                                                                className='create_tournament_form_checkbox'
                                                                                onChange={(e) => handleAddOrRemoveParticipants(member.playerId ?? '', TeamParticipantEnum.ContentMaker, e.target.checked)}
                                                                            />
                                                                            
                                                                            {isEditMode && partner?.partnerId && (
                                                                                <Form.Check
                                                                                    label="Владелец"
                                                                                    type="checkbox"
                                                                                    checked={isMemberWithRole(member.playerId ?? '', TeamParticipantEnum.Owner)}
                                                                                    className='create_tournament_form_checkbox'
                                                                                    onChange={(e) => handleAddOrRemoveParticipants(member.playerId ?? '', TeamParticipantEnum.Owner, e.target.checked)}
                                                                                />
                                                                            )}
                                                                        </Form.Group>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className='infoText'>В команде еще нет персонала</div>
                                                )}
                                                
                                                <PlayerNicknameSearch onPlayerSelect={handleAddTeamStaff} />
                                            </div>
                                        </Form.Check>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </Container>
    );
};