import { HubConnection } from '@microsoft/signalr';

export const useNotificationActions = (connection: HubConnection | null) => {

    const notificationActionHandlers: {
        [templateId: string]: {
            [action: string]: (notificationId: string) => Promise<void>;
        };
    } = {
        'TeamRequest': {
            'accept': async (notificationId: string) => {
                try {
                    await connection?.invoke("AcceptTeamNotification", notificationId);
                } catch (err) {
                    console.error('Error accepting team invite:', err);
                }
            },
            'decline': async (notificationId: string) => {
                try {
                    await connection?.invoke("DeclineTeamNotification", notificationId);
                } catch (err) {
                    console.error('Error declining team invite:', err);
                }
            }
        },
        'DeleteNotification': {
            'accept': async (notificationId: string) => {
                try {
                    await connection?.invoke("RemoveNotification", notificationId);
                } catch (err) {
                    console.error('Error deleting notification:', err);
                }
            }
        },
        'TournamentStaffRequest': {
            'accept': async (notificationId: string) => {
                try {
                    await connection?.invoke("AcceptAdditionalTournamentSlotInvitation", notificationId);
                } catch (err) {
                    console.error('Error accepting additional tournament slot invite:', err);
                }
            },
            'decline': async (notificationId: string) => {
                try {
                    await connection?.invoke("DeclineAdditionalTournamentSlotInvitation", notificationId);
                } catch (err) {
                    console.error('Error declining additional tournament slot invite:', err);
                }
            }
        },
        'TeamRoleRequest': {
            'accept': async (notificationId: string) => {
                try {
                    await connection?.invoke("AcceptAdditionalTeamSlotInvitation", notificationId);
                } catch (err) {
                    console.error('Error accepting additional team slot invite:', err);
                }
            },
            'decline': async (notificationId: string) => {
                try {
                    await connection?.invoke("DeclineAdditionalTeamSlotInvitation", notificationId);
                } catch (err) {
                    console.error('Error declining additional team slot invite:', err);
                }
            }
        }
    };

    const handleAction = async (notificationId: string, action: string | null, templateId: string | null) => {
        if (!action || !templateId) {
            console.warn(`Некорректное действие: action='${action}', templateId='${templateId}'`);
            return;
        }
        const handlers = notificationActionHandlers[templateId];
        if (handlers && handlers[action]) {
            await handlers[action](notificationId);
        } else {
            console.warn(`Нет обработчика для действия '${action}'`);
        }
    };

    return { handleAction };
};
