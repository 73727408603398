import { useState, useContext, RefObject, useEffect, SetStateAction } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../app/store/AppContext';
import { TeamParticipantEnum } from '../lib/TeamParticipantEnum';
import { IPlayerNicknameSearchModel } from '../../../widgets/playerNicknameSearch/model/IPlayerNicknameSearch';
import { ITeamParticipantVM } from '../lib/ITeamParticipantVM';
import { DefaultAvatars } from '../../../shared/helpers/constants/defaultAvatars';
import { createTeam, getTeamInfo, updateTeam } from '../../../shared/api/playerActionsAPI';
import { updateContext } from '../../../shared/helpers/logic/CommonLogic';
import { ICreateTeamViewModel } from '../../../entities/team/model/ICreateTeamViewModel';
import { CommonConstants } from '../../../shared/helpers/constants/commonConstants';
import { SystemRolesEnum } from '../../../shared/models/enums/SystemRolesEnum';
import { ITeamPageModel } from '../../../entities/team/model/ITeamPageModel';

export const useTeam = (
    isEditMode: boolean,
    teamStaff: IPlayerNicknameSearchModel[],
    teamParticipants: ITeamParticipantVM[],
    creator: any,
    userSystemRole: any,
    formRef1: RefObject<HTMLFormElement>,
    formRef2: RefObject<HTMLFormElement>,
    randomAvatarIdx: number, 
    setCurrentAvatar: React.Dispatch<SetStateAction<string>>
) => {
    const { player, partner } = useContext(AppContext);
    const navigate = useNavigate();
    const { teamId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [validatedSecond, setValidatedSecond] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [teamInfo, setTeamInfo] = useState<ITeamPageModel>();
    
    const [teamData, setTeamData] = useState<ICreateTeamViewModel>({
        initiatorId: player?.playerId ? player?.playerId : partner?.partnerId,
        creatorId: creator?.creatorId ?? CommonConstants.GuidEmpty,
        createdBy: userSystemRole,
        gameTypeId: '',
        city: '',
        name: '',
        tag: '',
        description: '',
        creationDate: '',
        score: 0,
        logoUrl: null,
        logoStringUrl: null,
        website: null,
        contactEmail: '',
        participants: []
    });
    
    const hasRoleByRoleType = (roleType: TeamParticipantEnum) => {
        return teamParticipants.some(participant =>
            participant.participantTypes.includes(roleType)
        );
    };
    
    const handleTeamAction = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);
        
        const form1 = formRef1.current;
        const form2 = formRef2.current;
        
        const isForm1Valid = form1?.checkValidity() ?? false;
        const isForm2Valid = form2?.checkValidity() ?? false;
        
        if (!isForm1Valid || !isForm2Valid) {
            if (!isForm1Valid) {
                setValidated(true);
            }
            
            if (!isForm2Valid) {
                setValidatedSecond(true);
            }
            
            setIsLoading(false);
            setShowAlert(true);
            
            return;
        }
        setValidated(true);
        setValidatedSecond(true)
        
        try {
            const form = event.currentTarget as HTMLFormElement;
            
            if (form.checkValidity() === false) {
                event.stopPropagation();
                return;
            }
            
            if (!teamParticipants.some(p => p.participantTypes.includes(TeamParticipantEnum.Player))) {
                alert('В команде нет игроков!');
                return;
            }
            
            if (!hasRoleByRoleType(TeamParticipantEnum.Captain)) {
                alert('В команде должен быть капитан!');
                return;
            }
            
            const insufficientRoles = teamStaff.some(staff => {
                const participant = teamParticipants.find(part => part.participantId === staff.playerId);
                
                if (!participant || participant.participantTypes.length === 0) {
                    return true;
                } else if (participant.participantTypes.length === 1) {
                    return participant.participantTypes[0] === TeamParticipantEnum.Player;
                }
                
                return false;
            });
            
            if (insufficientRoles) {
                alert('Не у всего персонала есть роль!');
                return;
            }
            
            if (
                !teamParticipants.some(p =>
                    p.participantId === player?.playerId &&
                    [TeamParticipantEnum.Player, TeamParticipantEnum.Manager, TeamParticipantEnum.Captain, TeamParticipantEnum.ContentMaker, TeamParticipantEnum.Coach, TeamParticipantEnum.Owner]
                    .some(role => p.participantTypes.includes(role))
                )
            ) {
                alert('Вас нет в команде!');
                return;
            }
            
            const captainWithoutPlayerRole = teamParticipants.some(p =>
                p.participantTypes.includes(TeamParticipantEnum.Captain) &&
                !p.participantTypes.includes(TeamParticipantEnum.Player)
            );
            
            if (captainWithoutPlayerRole) {
                alert('Капитан должен быть игроком!');
                return;
            }
            
            const isPlaerWithCoach = teamParticipants.some(p =>
                p.participantTypes.includes(TeamParticipantEnum.Coach) &&
                p.participantTypes.includes(TeamParticipantEnum.Player)
            );
            
            if (isPlaerWithCoach) {
                alert('Тренер не может быть игром!');
                return;
            }
            
            const formData = new FormData();
            
            formData.append('participantsJSON', JSON.stringify(teamParticipants));
            
            if (teamData.logoUrl) {
                formData.append('logoUrl', teamData.logoUrl);
            } else {
                formData.append('logoStringUrl', DefaultAvatars.teamAvatars[randomAvatarIdx]);
            }
            
            Object.entries(teamData).forEach(([key, value]) => {
                if (value !== undefined && value !== null) {
                    formData.append(key, String(value));
                }
            });
            
            let teamResponse;
            
            if (isEditMode) {
                teamResponse = await updateTeam(formData, teamId);
                
                if (teamResponse && teamResponse != null) {
                    navigate(`/team/${teamInfo?.teamId}`)
                }
            } else {
                teamResponse = await createTeam(formData);
                
                if (teamResponse && teamResponse != null && teamResponse.teamId) {
                    await updateContext(player, undefined);
                    navigate(`/team/${teamResponse.teamId}`)
                }
            }
        } catch (error) {
            console.error('Create Team error:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        if (isEditMode) {
            getTeamInfo(teamId)
                .then(data => {
                    if (data) {
                        setTeamData({
                            initiatorId: data.playerCreatorId ? data.playerCreatorId : data.partnerCreatorId ?? '',
                            creatorId: data.playerCreator?.playerId ?? CommonConstants.GuidEmpty,
                            createdBy: SystemRolesEnum.Player,
                            gameTypeId: data.gameTypeId ?? '',
                            city: data.city ?? '',
                            name: data.name ?? '',
                            tag: data.tag ?? '',
                            description: data.description ?? '',
                            creationDate: data.creationDate ?? '',
                            score: data.score ?? 0,
                            logoUrl: null,
                            logoStringUrl: data.logoUrl ?? null,
                            website: data.website ?? '',
                            contactEmail: data.contactEmail ?? '',
                            participants: []
                        })
                        setTeamInfo(data)
                    }
                }
                )
                .catch(error => {
                    console.error('Error fetching teams:', error);
                })
        }
    }, [teamId]);
    
    useEffect(() => {
        if (isEditMode) {
            if (teamData?.logoUrl) {
                setCurrentAvatar(`${process.env.REACT_APP_IMG_STORAGE_URL}${teamData.logoUrl}`);
            } else {
                setCurrentAvatar(`${process.env.REACT_APP_IMG_STORAGE_URL}${DefaultAvatars.teamAvatars[randomAvatarIdx]}`);
            }
        }
    }, [randomAvatarIdx, teamData?.logoUrl]);
    
    return {
        teamData,
        setTeamData,
        handleTeamAction,
        isLoading,
        validated,
        validatedSecond,
        showAlert,
        teamInfo
    };
};
