import React from 'react';
import animationData from '../../../shared/assets/animations/404_animation2.json';
import './NotFound.css';
import Lottie from 'react-lottie';

export const NotFound: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className='notFound_container'>
      <div className='lottie_container'>
        <h3 className='h3_first'>4</h3>
        <div className='lottie'>
          <Lottie
            options={defaultOptions}
            width={400} 
          />
        </div>
        <h3 className='h3_second'>4</h3>
      </div>
      <div className='notFound_text'>
        СТРАНИЦА НЕ НАЙДЕНА
      </div>
    </div>
  );
};