import React from 'react';
import Lottie from 'react-lottie';
import './CustomSpinners.css';
import animationData from '../../assets/animations/customSpinner1.json';

interface ICustomSpinnersProps {
    size?: string;
    sizeSpinnerContainer?: string;
}

const CustomSpinner1: React.FC<ICustomSpinnersProps> = (size, sizeSpinnerContainer) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            {
                size.size ? (
                    <div className='customSpinner_container' style={{ height: size.sizeSpinnerContainer, width: size.sizeSpinnerContainer }}>
                        <div className="customSpinner" style={{ height: size.size, width: size.size }}>
                            <Lottie options={defaultOptions} />
                        </div>
                    </div>
                ) : (
                    <div className='customSpinner_container'>
                        <div className="customSpinner">
                            <Lottie options={defaultOptions} />
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default CustomSpinner1;