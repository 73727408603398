import React, { useRef, useState } from 'react';
import './NotificationCenter.css';
import NotificationUnit from './notificationUnit/NotificationUnit';
import { useNotifications } from '../hooks/useNotifications';
import { useNotificationActions } from '../hooks/useNotificationActions';

interface NotificationCenterProps {
    isVisible: boolean;
    onUnreadNotificationsChange: (hasUnread: boolean) => void;
}

export const NotificationCenter: React.FC<NotificationCenterProps> = ({ isVisible, onUnreadNotificationsChange }) => {
    const [activeTab, setActiveTab] = useState('inbox');
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { notifications, connection } = useNotifications(isVisible, onUnreadNotificationsChange);
    const { handleAction } = useNotificationActions(connection);

    const inboxNotifications = notifications.filter(n => n.isPong === false);
    const sentNotifications = notifications.filter(n => n.isPong === true);


    return (
        <div ref={containerRef} className={`notification_center ${isVisible ? 'visible' : 'hidden'}`}>
            <div className='notification_center_header'>Центр уведомлений</div>
            <div className='notification_center_switch'>
                <div
                    onClick={() => setActiveTab('inbox')}
                    className={activeTab === 'inbox' ? 'notif_tab_active' : ''}
                >
                    Входящие
                </div>
                <div
                    onClick={() => setActiveTab('sent')}
                    className={activeTab === 'sent' ? 'notif_tab_active' : ''}
                >
                    Отправленные
                </div>
            </div>

            <div className='notification_units_container'>
                {activeTab === 'inbox' ? (
                    inboxNotifications.length > 0 ? (
                        inboxNotifications
                            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                            .map((notification) => (
                                <NotificationUnit
                                    key={notification.notificationId}
                                    notification={notification}
                                    onAction={handleAction}
                                />
                            ))
                    ) : (
                        <p className='notification_units_empty'>Здесь появятся Ваши уведомления.</p>
                    )
                ) : (
                    sentNotifications.length > 0 ? (
                        sentNotifications
                            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                            .map((notification) => (
                                <NotificationUnit
                                    key={notification.notificationId}
                                    notification={notification}
                                    onAction={handleAction}
                                />
                            ))
                    ) : (
                        <p className='notification_units_empty'>Нет отправленных уведомлений.</p>
                    )
                )}
            </div>
        </div>
    );
};