import { getPlayersByFilters } from "../../../shared/api/playerActionsAPI";
import { IPlayerNicknameSearchModel } from "../model/IPlayerNicknameSearch";

interface HandleSearchSubmitParams {
    searchValue: string;
    setSearchResults: (results: IPlayerNicknameSearchModel[] | null) => void;
    setShowAlert: (show: boolean) => void;
    setErrorMessage: (message: string) => void;
}

export const handleSearchSubmit = async ({
    searchValue,
    setSearchResults,
    setShowAlert,
    setErrorMessage,
}: HandleSearchSubmitParams) => {
    const filters = {
        nickNamePart: searchValue,
        playerWithoutTeam: false,
        showYourself: false,
    };
    
    try {
        const results = await getPlayersByFilters(filters);
        
        if (results === null || results.length === 0) {
            setShowAlert(true);
            setSearchResults([]);
            setErrorMessage('Игроки не найдены');
        } else {
            setSearchResults(results);
            setShowAlert(false);
        }
    } catch (unknownError) {
        console.error('Error fetching players:', unknownError);
        
        setSearchResults([]);
        setShowAlert(true);
        
        if (isErrorWithResponse(unknownError)) {
            setErrorMessage(unknownError.response.data);
        } else if (unknownError instanceof Error) {
            setErrorMessage(unknownError.message);
        } else {
            setErrorMessage('Произошла неизвестная ошибка при запросе');
        }
    }
};

export const handleSelectPlayer = (selectedPlayer: IPlayerNicknameSearchModel, onPlayerSelect: (player: IPlayerNicknameSearchModel) => void) => {
    onPlayerSelect(selectedPlayer);
};

function isErrorWithResponse(error: any): error is { response: { data: any } } {
    return error && error.response && typeof error.response.data === 'string';
}