import React, { useEffect, useRef } from 'react';
import { INotificationModel } from '../../model/INotificationModel';
import moment from 'moment-timezone';


const NotificationUnit: React.FC<{
    notification: INotificationModel;
    onAction: (notificationId: string, action: string | null, templateId: string | null) => void;
}> = ({ notification, onAction }) => {

    const containerRef = useRef<HTMLDivElement>(null);

    const formatDate = (isoString: string) => {
        return moment(isoString).format('DD.MM.YY HH:mm');
    };

    useEffect(() => {
        const buttons = containerRef.current?.querySelectorAll('button[data-action]');
        const clickHandlers: Array<{ button: HTMLButtonElement, handler: () => void }> = [];

        buttons?.forEach(element => {
            const button = element as HTMLButtonElement;

            const action = button.getAttribute('data-action');
            const templateId = button.getAttribute('data-template-id');

            const handler = async () => {
                try {
                    if (action === 'accept' && templateId === 'DeleteNotification' && !notification.isPong) {
                        await onAction(notification.notificationId, action, templateId);
                        containerRef.current?.classList.add('fade-out');
                        setTimeout(() => { }, 500);
                    } else {
                        await onAction(notification.notificationId, action, templateId);
                    }
                } catch (err) {
                    console.error('Error handling notification action:', err);
                }
            };

            button.addEventListener('click', handler);
            clickHandlers.push({ button, handler });
        });

        return () => {
            clickHandlers.forEach(({ button, handler }) => {
                button.removeEventListener('click', handler);
            });
        };
    }, [notification, onAction]);

    return (
        <div ref={containerRef} className={`notification_unit ${notification.state === 1 ? 'unread' : ''}`}>
            <button className='notification_delete' data-template-id="DeleteNotification" data-action="accept">X</button>
            <div className="notif_html" dangerouslySetInnerHTML={{ __html: notification.htmlContent }} />
            <span className="notification_date">{formatDate(notification.date)}</span>
        </div>
    );
};

export default NotificationUnit;